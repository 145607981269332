<template>
	<div class='contianer' v-loading='loading'>
		<div class='tips' v-if="haveAuth">
			<span>温馨提示：</span>
			<span>1. 若小程序审核不通过，可能是微信小程序页面设置不完善或者微信小程序类目和商品类目不符导致。</span>
			<span>2. 若是因微信类目和商品类目审核不通过，您可根据微信返回的提示，前往微信小程序官网修改后重新提交，或者删除所需类目的商品。</span>
			<span>3. 若是因内容缺失审核不通过，请确保你的小程序页面已设置，并且有正式上架售卖的商品（商品名称不可包含“测试”和“test”等文案）。</span>
			<span>4. 审核不通过，不会影响目前已在线上运行的老版本小程序。</span>
		</div>
		<div class="tip-wraper" v-if="bothNoAuth">授权微信小程序</div>

		<div v-for='(t,i) in dataList' :key='i'>
			<!-- 版本3.3.2， 员工小程序模块去掉了， 不需要员工小程序配置 -->
			<div v-if="t.MicroProgramType===0">  <!--  ||(t.MicroProgramType===1 && mallInfo.IsUseQyWeixin) -->
				<noMiniprogram v-if="!t.MicroProgramData.Id" :miniData="t" @refresh="getDataList"></noMiniprogram>
				<el-card v-else class="box-card program-card">
					<div slot="header" class="flexRow" style="justify-content: space-between;">
						<div>
							<span v-if='t.MicroProgramType==0'>基本信息</span>
							<span v-if='t.MicroProgramType==1'>员工小程序</span>
							<el-button type='text' style='margin-left:10px;'
								@click='handleUpdate(t.MicroProgramData.Id)'>更新小程序信息
							</el-button>
							<el-button type="text" v-if='t.MicroProgramType==0' @click="againauthorization(t)">重新授权
							</el-button>
						</div>
						<div>
							<el-link style='margin-top:10px;' v-if='t.MicroProgramType==0' type="info" target="_blank"
								href="https://jusnn6k8al.feishu.cn/docs/doccn7OuGVazCDrVXgEbl2d8xL7">
								<i class="el-icon-document"></i>商城小程序授权绑定说明
							</el-link>
							<el-link style='margin-top:10px;' v-if='t.MicroProgramType==1' type="info" target="_blank"
								href="https://jusnn6k8al.feishu.cn/docs/doccn7OuGVazCDrVXgEbl2d8xL7">
								<i class="el-icon-document"></i>员工小程序授权绑定说明
							</el-link>
						</div>
					</div>
					<!-- 新增提示 -->
					<div class="remind" v-if="!message.IsOpen">
						<span style="margin-left:16px">订阅小程序审核结果通知，当小程序审核通过或审核否决时，我们将通过短信给你发送提醒。</span>
						<span class="co" @click="handclick">前往订阅</span>
					</div>
					<div class='program-info'>
						<label>小程序名称：</label>
						<span class='info' v-if='t.MicroProgramData.NickName'>{{t.MicroProgramData.NickName}}</span>
						<span class='info' v-else>未设置小程序名称</span>
					</div>

					<div class='program-info'>
						<label>小程序头像：</label>
						<img v-if='t.MicroProgramData.HeadUrl' :src='t.MicroProgramData.HeadUrl'
							style='width: 64px;height: 64px;object-fit: contain;'>
						<span v-else class='info'>未上传小程序头像</span>
					</div>

					<div class='program-info' style="display:flex;">
						<label>小程序类目：</label>
						<div style="font-size: 14px;">
							<!-- 改动-->
							<div v-for='(tt,index) in t.ResultList' :key="index">
								<div v-if='tt.SecondCategoryList.length'>
									<div v-for='(ttt,index1) in tt.SecondCategoryList' :key="index1">
										{{tt.First_class}}>{{ttt.Second_class}}
									</div>
								</div>
								<div v-else>{{tt.First_class}}</div>
							</div>
							<div>
								<el-link style="margin-top:5px" type="primary" target="_blank"
									href="https://jusnn6k8al.feishu.cn/docs/doccn9AGced3zVWOLut2Ow2CYug">添加类目</el-link>
							</div>
						</div>
					</div>

					<div class='program-info'>
						<label>线上版本：</label>
						<span class='info'>{{t.MicroProgramData.CurrentVersion}}</span>
					</div>
					<div class='program-info'>
						<label>更新状态：</label>
						<span class='info'>{{t.MicroProgramData.LatestVersion}}</span>
						<span class='info' style='margin-left:20px;'
							:style="{'color':t.WxappStatus ==3 ? '#F56C6C':'inherit'}">{{(t.WxappStatus ==5&&t.MicroProgramData.LatestVersion!=t.MicroProgramData.CurrentVersion)?'待审核':t.WxappStatusDescrt}}</span>
						<el-button type='text' style='margin-left:10px;'
							v-if='t.WxappStatus ==1&&t.MicroProgramData.LatestVersion!=t.MicroProgramData.CurrentVersion'
							@click='handleSubmitCheck(t)'>提交审核</el-button>
						<el-button type='text' style='margin-left:10px;' v-if='t.WxappStatus ==2'
							@click='handleBackCheck(t.MicroProgramData.Id)'>撤消审核</el-button>
						<el-button type='text' style='margin-left:10px;'
							v-if='t.WxappStatus ==3&&t.MicroProgramData.LatestVersion!=t.MicroProgramData.CurrentVersion'
							@click='handleSubmitCheck(t)'>重新提交审核</el-button>
						<el-button type='text' style='margin-left:10px;' v-if='t.WxappStatus ==4'
							@click='handleRelease(t.MicroProgramData.Id)'>发布上线</el-button>
						<el-button type='text' style='margin-left:10px;'
							v-if='t.WxappStatus ==5&&t.MicroProgramData.LatestVersion!=t.MicroProgramData.CurrentVersion'
							@click='handleSubmitCheck(t)'>提交审核</el-button>
							<!-- <el-button type='text' style='margin-left:10px;'
							@click='$router.push({path:"/authResult/fail3"})'>提交审核</el-button> -->
					</div>
					<div class='program-info-error' v-if='t.WxappStatus ==3'>
						{{t.MicroProgramData.Reason}}
					</div>
				</el-card>
				<el-card style="margin-top:10px" class="box-card program-card" >
					<div>接口权限</div>
					<div style="display:flex;margin-top:30px">
						<div class="program-info" style="flex-shrink:0;font-size:14px">
							<label style="width:150px">获取用户收货地址：</label>
						</div>
						<div>
							<div class="flexRowCenter">
								<span style="font-size:14px;margin-right:20px">{{t.ChooseAddressStateValue}}</span>
								<el-link href="https://jusnn6k8al.feishu.cn/docx/doxcnAnNOlvW9OVWY27u4bdeYqw" :underline="false"
								target="_blank" type="primary" v-if="t.GetLocationState==1||t.GetLocationState==2">查看开通说明</el-link>
							</div>
							<div class="grayfont" style="margin-top:5px">获取微信收货地址，便于客户下单时快速填充收货地址信息</div>
						</div>
					</div>
					<div style="display:flex;margin-top:30px">
						<div class="program-info" style="flex-shrink:0;font-size:14px">
							<label style="width:150px">获取当前的地理位置：</label>
						</div>
						<div>
							<div class="flexRowCenter">
								<span style="font-size:14px;margin-right:20px">{{t.GetLocationStateValue}}</span>
								<el-link href="https://jusnn6k8al.feishu.cn/docx/doxcnMj7pETgYSckEkhkKeCbmJd" :underline="false"
								target="_blank" type="primary" v-if="t.GetLocationState==1||t.GetLocationState==2">查看开通说明</el-link>
							</div>
							<div style="margin-top:5px">
								<div class="grayfont">1.客户下单时选择到店自提或同城配送，获取当前的地理位置，可以更好的为客户提供服务，提升下单体验；</div>
								<div class="grayfont">2.如果只做线上快递发货业务，可不开通该接口</div>
								<div class="grayfont" >3.该接口目前只针对国内主体部分类目的小程序开放，
									<el-link href="https://developers.weixin.qq.com/miniprogram/dev/api/location/wx.getLocation.html#%E7%94%B3%E8%AF%B7%E5%BC%80%E9%80%9A" :underline="false"
									target="_blank" type="primary">查看类目要求</el-link>
								</div>
							</div>
						</div>
					</div>
				</el-card>
			</div>

		</div>

		


		<el-dialog title="提示" :visible.sync="dialogFormVisible" width="600px">
			<div style="line-height:18px">
				重新授权不可更换另一个小程序授权绑定，请选择现在已经绑定的小程序：<span
					style="color:#F56C6C">{{proTitle.title}}</span>。若你需要更换小程序授权绑定，请点击下方[联系客服]协助操作。
			</div>
			<div style="margin-top:40px;text-align:right">
				<el-button @click="dialogFormVisible = false">关闭</el-button>
				<!-- <el-popover	placement="bottom"	width="200"	trigger="hover" style="margin:0px 15px">
					<div>

					</div> -->
				<!-- slot="reference" -->
				<el-button type="primary" plain @click="serviceshow = true">联系客服</el-button>
				<!-- </el-popover> -->
				<el-button type="primary" @click="continueto">继续授权</el-button>
			</div>
		</el-dialog>
		<!-- 客服 -->
		<el-dialog title="提示" :visible.sync="serviceshow" width="400px">
			<div style="line-height:18px">
				授权或注册过程中，若遇到问题，使用微信扫描下方二维码，<span style="color:#E6A23C">添加专属客服</span>，一对一为您解答疑惑
			</div>
			<div style="width:100%;text-align:center">
				<img src="../../assets/img/20210719171848.jpg" style="width:150px;height:150px;margin-top:20px" alt="">
			</div>
			<div style="margin-top:20px;text-align:right">
				<el-button size="mini" @click="serviceshow = false">我知道了</el-button>
			</div>
		</el-dialog>
		<!-- 缺少权限76 -->
		<el-dialog title="提示" :visible.sync="lackPermissionsshow" width="420px" top="40vh" custom-class="msgboxdialog">
			<div class = "flexRow">
				<i class="el-icon-warning" style="color:#E6A23C;font-size:18px"></i>
				<div class = "lackPermissionsfont" style="margin-left:10px">
					版本审核提交失败。商城小程序缺少「小程序违规与交易投诉管理」权限，请点击下方重新授权按钮，勾选该权限并重新授权。
				</div>
			</div>
			<div style="text-align:right;margin-top:20px">
				<el-button @click="lackPermissionsshow=false">取消</el-button>
				<el-button @click="serviceshow=true,lackPermissionsshow=false">联系客服协助</el-button>
				<el-button type="primary" @click="continueto">前往重新授权</el-button>
			</div>
		</el-dialog>
		<!-- 版本审核提交失败 -->
		<el-dialog title="版本审核提交失败" :visible.sync="auditFailureShow" width="1000px" custom-class="msgboxdialog2">
			<div class ="lackPermissionsfont">
				小程序存在未处理的违规记录，请先前往微信小程序处理。若您已提交申诉，请等待微信官方审核通过申诉请求。
				 <el-link href="https://jusnn6k8al.feishu.cn/docs/doccnTguj0AjYjydbSyUrMmr4Nh" target="_blank"
						:underline="false" type="primary">查看申诉说明</el-link>
			</div>
			<div class ="lackPermissionsfont">
				注意：小程序违规长期不处理或多次出现违规记录，将会面临小程序
				<span style="color:#f56c6c">下架、封禁</span>
				等风险。请商家参照
				 <el-link href="https://developers.weixin.qq.com/miniprogram/product/" target="_blank"
						:underline="false" type="primary">《微信小程序平台运营规范》</el-link>，运营商城小程序
			</div>
			<div style="margin-top:15px">
				<el-table :data = "tableList" max-height="500">
					<el-table-column prop="create_time" label="违规处罚时间"></el-table-column>
					<el-table-column prop="illegal_reason" label="违规原因"></el-table-column>
					<el-table-column prop="illegal_content" label="违规内容" width="500"></el-table-column>
					<el-table-column prop="" label="处理状态">
						<template slot-scope="scope">
							<div>
								<span :style="{color:scope.row.appeal_status==3?'#f56c6c':'#606266'}">{{scope.row.illegal_content}}</span>
							</div>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import apiList from '@/api/other';
	import noMiniprogram from './components/noMiniprogram.vue';
	import {
		mallFreeVersionConfigInfo,
		mallNoticeSubscribeConfig //获取消息订阅信息
	} from '@/api/goods.js';

	import {
		mapGetters
	} from 'vuex';

	export default {
		components: {
			noMiniprogram
		},
		data() {
			return {
				loading: false,
				dataList: [],
				dialogFormVisible: false,
				proTitle: {
					title: '',
					Id: ''
				},
				serviceshow: false,
				data: {},
				message: {},

				lackPermissionsshow:false,
				auditFailureShow:false,
				tableList:[],
				nowMsg:{},
			}
		},
		computed: {
			...mapGetters(['mallInfo']),
			haveAuth() {
				if (!this.dataList.length) return true;
				return this.dataList[0].MicroProgramData.Id || this.dataList[1].MicroProgramData.Id
			},
			bothNoAuth() {
				if (!this.dataList.length) return false;
				if (this.mallInfo.IsUseQyWeixin) {
					return !this.dataList[0].MicroProgramData.Id && !this.dataList[1].MicroProgramData.Id
				} else {
					var list = this.dataList.filter(item => {
						return item.MicroProgramType === 0;
					})
					return !list[0].MicroProgramData.Id
				}

			}
		},
		created() {
			this.getDataList()
			this.getMessageList()
		},
		methods: {
			handclick() {
				this.$router.push({
					path: '/setting/Newssubscription',
					// path:"",//路由跳转路径
					//参数为整个对像query:{parameter:JSON.stringify(this.message)},
				})
			},
			//获取消息订阅信息
			async getMessageList() {
				try {
					let result_remind = await mallNoticeSubscribeConfig()
					this.message = result_remind.Result.find((i) => {
						return i.Type == 1
					}) //
					console.log(result_remind)
					let arr = result_remind.Result.find((v) => {
						return v.Type
					})
					console.log(arr.Type, arr.TypeValue, arr)
				} finally {}
			},
			continueto() {
				// console.log(this.data.WxAppAuthUrl)
				window.open(this.data.WxAppAuthUrl)
				this.lackPermissionsshow = false
				this.$confirm('请在新窗口扫码完成授权绑定', '提示', {
					confirmButtonText: '我已绑定成功',
					cancelButtonText: '绑定失败',
					type: 'warning',
					closeOnClickModal: false,
					showClose: false
				}).then(() => {
					this.dialogFormVisible = false
					this.checkBind();
				}).catch(() => {
					this.dialogFormVisible = false
					this.checkBind();
				});
			},
			conncatCustomerService() {
				this.serviceshow = true;
			},
			async checkBind() {
				try {

					let res = await apiList.mcroProgramList();
					// console.log(res)
					var list = res.filter(item => {
						//因为只有商城小程序 重新绑定
						return item.MicroProgramType === 0;
					})

					if (list.length && list[0].MicroProgramData.Id) {
						this.getDataList()
					} else {
						this.bindError();
					}
				} catch (e) {
					//TODO handle the exception
				}
			},
			bindError() {
				this.$confirm('授权绑定失败', '提示', {
					confirmButtonText: '重新授权',
					cancelButtonText: '联系客服',
					type: 'warning',
					closeOnClickModal: false,
				}).then(() => {
					this.continueto();
				}).catch(() => {
					this.conncatCustomerService();
				});
			},
			againauthorization(t,notip) {
				// console.log(t)
				this.proTitle = {
					title: t.MicroProgramData.Title,
					Id: t.MicroProgramData.Id
				}
				this.getData()
				if(notip){
					return 
				}
				this.dialogFormVisible = true
			},
			async getData() {
				let result = await mallFreeVersionConfigInfo()
				// console.log(result.Result,111)
				this.data = result.Result;
			},
			async getDataList() {
				try {
					this.loading = true
					let result = await apiList.mcroProgramList();
					if (!result.length) {
						result = [{
							MicroProgramData: {
								Id: 0
							},
							MicroProgramType: 0
						}, {
							MicroProgramData: {
								Id: 0
							},
							MicroProgramType: 1
						}]
					} else if (result.length === 1) {
						if (result[0].MicroProgramType === 0) {
							result.push({
								MicroProgramData: {
									Id: 0
								},
								MicroProgramType: 1
							})
						} else {
							result = [{
								MicroProgramData: {
									Id: 0
								},
								MicroProgramType: 0
							}].concat(result)
						}
					}

					this.dataList = result

				} catch (e) {} finally {
					this.loading = false
				}
			},
			async handleUpdate(id) {
				try {
					let result = await apiList.mcroProgramUpdate({
						McroProgramId: id
					})
					if (result.IsSuccess) {
						this.$message({
							type: 'success',
							message: '更新成功',
							showClose: true
						})
						this.getDataList()
					}
				} catch (e) {}
			},
			async handleSubmitCheck(val) {
				let submitAuditModel = {
					Address: val.MicroProgramData.Address,
					Title: val.MicroProgramData.Title,
					Tag: val.MicroProgramData.Tag,
					FirstId: val.MicroProgramData.FirstId,
					FirstClass: val.MicroProgramData.FirstClass,
					SecondId: val.MicroProgramData.SecondId,
					SecondClass: val.MicroProgramData.SecondClass,
					ThirdId: val.MicroProgramData.ThirdId,
					ThirdClass: val.MicroProgramData.ThirdClass,
				}
				this.nowMsg = val
				this.loading = true
				try {
					let data = {
						Id: val.MicroProgramData.Id,
						SubmitAuditModel: JSON.stringify(submitAuditModel),
						noError:true
					}
					let result = await apiList.microprogramsubmitaudit(data)
					// result.ErrorCode = 18000009
					if (result.IsSuccess) {
							this.$message({
								type: 'success',
								message: '新版本审核申请提交成功',
								showClose: true
							})
							this.getDataList()
					}else if(result.ErrorCode==18000009){
						this.lackPermissionsshow = true
						this.againauthorization(val,true)
					}else if(result.ErrorCode==18000010){
						this.auditFailureShow = true
					}else{
						this.$message.error(result.Message)
					}
				}finally {
					this.loading = false
				}
			},
			// 撤销
			handleBackCheck(id) {
				this.$confirm('是否确认撤消小程序审核？1天仅可撤消1次,一个月最多可撤消10次', {
					confirmButtonText: '确认撤销',
					cancelButtonText: '取消',
					center: true
				}).then(async () => {
					try {
						let result = await apiList.microprogramrecallcheck({
							Id: id
						})
						if (result.IsSuccess) {
							this.$message({
								type: 'success',
								message: '撤销成功',
								showClose: true
							})
							this.getDataList()
						}
					} catch (e) {}
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消撤销'
					});
				});
			},
			// 发布上线
			async handleRelease(id) {
				try {
					let result = await apiList.microprogramrelease({
						Id: id,
					})
					if (result.IsSuccess) {
						this.$message({
							type: 'success',
							message: '发布成功',
							showClose: true
						})
						this.getDataList()
					}
				} catch (e) {}
			},
		}
	}
</script>

<style lang='less' scoped>

.grayfont{
	font-size: 12px;
	color:#999999;
	line-height: 1.5;
}
	.remind {
		width: 742px;
        height: 40px;
        background: #FBFDFF;
        border-radius: 2px 2px 2px 2px;
        opacity: 1;
        border: 1px solid #409EEF;
		font-size: 14px;
		color: #606266  ;
		line-height: 40px;
		margin-bottom: 10px;
	}

	.co {
		color: rgb(145, 204, 253);
		cursor: pointer;
	}

	.contianer {
		padding: 10px;
		background: #fff;

		.tip-wraper {
			padding-top: 10px;
			padding-left: 10px;
			font-size: 16px;
			font-weight: bold;
		}

		.tips {
			border: 1px solid #ddd;
			padding: 10px;
			color: #999;
			font-size: 14px;
			line-height: 25px;

			span {
				display: block;
			}
		}

		.program-card {
			margin-top: 10px;

			.program-info-error {
				color: #F56C6C;
				margin-left: 20px;
				font-size: 15px;
			}

			.program-info {
				margin-bottom: 20px;

				label {
					text-align: right;
					display: inline-block;
					font-size: 14px;
					color: #606266;
					font-weight: 700;
					width: 90px;
					margin-right: 5px;
				}

				.info {
					font-size: 14px;
				}

				img {
					vertical-align: top;
				}
			}
		}
	}

	.flexRow {
		display: flex;
		flex-direction: row;
		// justify-content: space-between;
	}
	.lackPermissionsfont{
		font-size: 14px;
		color: #606266;
		line-height: 1.5;
	}
</style>
<style lang = "less">
.msgboxdialog{
	border-radius: 4px;
	.el-dialog__header{
		padding: 15px 20px 10px 20px;
	}
	.el-dialog__body{
		padding: 10px 20px 15px 20px;
	}
}
.msgboxdialog2{
	.el-dialog__body{
		padding: 10px 20px 15px 20px;
	}
}
</style>
